import { WaypointRange } from "@/modes/mode-selectors";
import {
  MAX_ALTITUDE_COLOR,
  MIN_ALTITUDE_COLOR,
} from "@/utils/waypoints-color-gradient";
import {
  ColorBar,
  ExclamationMarkCircleFillIcon,
  FaroIconButton,
  FaroPopover,
  InfoIcon,
  neutral,
  yellow,
} from "@faro-lotv/flat-ui";
import { PropsWithChildren, ReactNode, useMemo, useRef, useState } from "react";

type HelpPopoverProps = {
  /** The title of the popover */
  title: string;

  /** The description of the popover */
  description: ReactNode;

  /** Function called when the icon button to open the popover is clicked */
  onClick?(): void;

  /** Variant of the help popover @default "info" */
  variant?: "info" | "warning";
};

/**
 * @returns A help icon button that opens a popover with the given title and description
 */
export function HelpPopover({
  title,
  description,
  onClick,
  children,
  variant = "info",
}: PropsWithChildren<HelpPopoverProps>): JSX.Element {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const color = variant === "info" ? neutral[100] : yellow[600];

  return (
    <>
      <FaroIconButton
        color={color}
        // As the color has a meaning for this icon it should not change on hover
        // the shadow is enough indication of the hovering
        hoverColor={color}
        size="xs"
        ref={buttonRef}
        onClick={() => {
          onClick?.();
          setIsPopoverOpen(true);
        }}
      >
        {variant === "info" ? (
          <InfoIcon sx={{ width: "16px", height: "16px" }} />
        ) : (
          <ExclamationMarkCircleFillIcon
            sx={{ width: "16px", height: "16px" }}
          />
        )}
      </FaroIconButton>
      <FaroPopover
        title={title}
        description={description}
        dark
        open={isPopoverOpen}
        anchorEl={buttonRef.current}
        onClose={() => setIsPopoverOpen(false)}
      >
        {children}
      </FaroPopover>
    </>
  );
}

type WaypointsElevationHelpProps = {
  /** The range of altitudes for the waypoints */
  range: WaypointRange;
};

/**
 * @returns Button allowing user to learn more about the Waypoints Elevation and the coloring of waypoints
 */
export function WaypointsElevationHelp({
  range,
}: WaypointsElevationHelpProps): JSX.Element {
  const { lowest, highest } = range;

  const colorBarValues = useMemo(
    () => ({
      // This is done so that the values are rounded to 2 decimal places and are still numbers
      startValue: Math.round(lowest * 100) / 100,
      endValue: Math.round(highest * 100) / 100,
      unit: "m",
    }),
    [lowest, highest],
  );

  return (
    <HelpPopover
      title="Waypoints elevation"
      description="Waypoints will be displayed with different colors according to their elevation."
    >
      <ColorBar
        colors={[MIN_ALTITUDE_COLOR, MAX_ALTITUDE_COLOR]}
        values={colorBarValues}
      />
    </HelpPopover>
  );
}

/**
 * @returns Button allowing user to learn more about the Waypoints Elevation and the showing of waypoints on floors
 */
export function ShowWaypointsOnFloorsHelp(): JSX.Element {
  return (
    <HelpPopover
      title="Show waypoints on floors"
      description="This option renders the waypoints on the floor, with a predefined offset. Turning this option off will show the waypoints at their real elevation."
    />
  );
}
