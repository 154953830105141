import { selectModeName } from "@/store/mode-selectors";
import { selectActiveElement } from "@/store/selections-selectors";
import { useAppSelector, useAppStore } from "@/store/store-hooks";
import { FaroTooltip, neutral, TranslateVar } from "@faro-lotv/flat-ui";
import { convertToDateTimeString } from "@faro-lotv/foundation";
import {
  GUID,
  IElement,
  isIElementImg360,
  isIElementSection,
  isIElementVideoRecording,
  pickClosestInTime,
} from "@faro-lotv/ielement-types";
import {
  selectAncestor,
  selectIsChildOfRooms,
} from "@faro-lotv/project-source";
import { Stack } from "@mui/material";
import { useCallback, useMemo } from "react";
import {
  TimestampDropdown,
  TimestampDropdownOptionLabel,
} from "./timestamp-dropdown/timestamp-dropdown";

type DataSessionsDropDownProps = {
  /** The list of datasets of interest for the current mode */
  datasets: IElement[];

  /** The element containing the time reference for the current active element  */
  referenceElement?: IElement;

  /** Called when the user selects a new data session from the drop down */
  onDataSessionChanged(id: GUID): void;
};

/**
 * @returns a dropdown to select the available odometric paths
 */
export function DataSessionsDropDown({
  datasets,
  referenceElement,
  onDataSessionChanged,
}: DataSessionsDropDownProps): JSX.Element | null {
  const store = useAppStore();
  const activeMode = useAppSelector(selectModeName);

  const activeElement = useAppSelector(selectActiveElement);
  // The pano info should be shown only when the viewer is showing a pano in walk or split mode
  const shouldShowPanoInfo = useMemo(
    () =>
      !!activeElement &&
      isIElementImg360(activeElement) &&
      (activeMode === "walk" || activeMode === "split"),
    [activeElement, activeMode],
  );
  const isRoomPano = useAppSelector(
    selectIsChildOfRooms(activeElement?.id ?? ""),
  );

  const selectedElement = useMemo(
    () =>
      referenceElement
        ? pickClosestInTime(referenceElement, datasets)
        : undefined,
    [datasets, referenceElement],
  );

  const labelFormatter = useCallback(
    (iElement: IElement) => {
      const videoRecording = selectAncestor(
        iElement,
        isIElementVideoRecording,
      )(store.getState());

      const referenceElement = videoRecording ?? iElement;

      return (
        <TranslateVar name="elementLabel">
          {convertToDateTimeString(referenceElement.createdAt)} -{" "}
          {referenceElement.name}
        </TranslateVar>
      );
    },
    [store],
  );

  // Formatter for the pano label (name + timestamp)
  const panoLabelFormatter = useCallback(
    (iElement: IElement) => {
      const section = selectAncestor(
        iElement,
        isIElementSection,
      )(store.getState());

      const referenceElement = section ?? iElement;

      return (
        <TranslateVar name="elementLabel">
          {referenceElement.name}{" "}
          {convertToDateTimeString(referenceElement.createdAt)}
        </TranslateVar>
      );
    },
    [store],
  );

  // Check if datasets contains referenceElement since it could be a room and we don't
  // want to show it
  if (datasets.length === 0 || !selectedElement) {
    return null;
  }

  return (
    <Stack
      direction="row"
      sx={{
        backgroundColor: neutral[999],
        zIndex: (theme) => theme.zIndex.snackbar,
        alignItems: "center",
        padding: "5px 12px",
        gap: 0.75,
        borderRadius: "4px",
      }}
    >
      <TimestampDropdown
        value={selectedElement.id}
        options={datasets}
        onChange={(e) => onDataSessionChanged(e.target.value)}
        labelFormatter={isRoomPano ? panoLabelFormatter : labelFormatter}
      />
      {shouldShowPanoInfo && !isRoomPano && activeElement && (
        <FaroTooltip title={panoLabelFormatter(activeElement)}>
          <TimestampDropdownOptionLabel
            iElement={activeElement}
            labelFormatter={panoLabelFormatter}
            sx={{ color: neutral[400], maxWidth: "200px" }}
          />
        </FaroTooltip>
      )}
    </Stack>
  );
}
