import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectWaypointsColoring } from "@/store/view-options/view-options-selectors";
import {
  setWaypointsColoring,
  WaypointsColoringOptions,
} from "@/store/view-options/view-options-slice";
import { Dropdown, FaroText, neutral, Option } from "@faro-lotv/flat-ui";
import { validateEnumValue } from "@faro-lotv/foundation";
import { Stack } from "@mui/material";
import { useMemo } from "react";
import { HelpPopover } from "../help-popover";

type WaypointsColorizationOptionsProps = {
  /** Whether the 'color by elevation' option should be disabled */
  disableColorByElevation: boolean;
  /** Whether the 'color by date' option should be disabled */
  disableColorByDate: boolean;
};

/** @returns An UI element with labels and dropdown providing waypoints colorization options to the user */
export function WaypointsColorizationOptions({
  disableColorByElevation,
  disableColorByDate,
}: WaypointsColorizationOptionsProps): JSX.Element {
  const waypointsOption = useAppSelector(selectWaypointsColoring);
  const dispatch = useAppDispatch();

  const dropdownOptions: Option[] = useMemo(
    () => [
      {
        key: WaypointsColoringOptions.default,
        value: WaypointsColoringOptions.default,
        label: (
          <FaroText variant="labelL" color={neutral[100]}>
            Default
          </FaroText>
        ),
        isDisabled: false,
      },
      {
        key: WaypointsColoringOptions.byElevation,
        value: WaypointsColoringOptions.byElevation,
        label: (
          <FaroText variant="labelL" color={neutral[100]}>
            By elevation
          </FaroText>
        ),
        isDisabled: disableColorByElevation,
      },
      {
        key: WaypointsColoringOptions.byCaptureDate,
        value: WaypointsColoringOptions.byCaptureDate,
        label: (
          <FaroText variant="labelL" color={neutral[100]}>
            By capture date
          </FaroText>
        ),
        isDisabled: disableColorByDate,
      },
    ],
    [disableColorByElevation, disableColorByDate],
  );

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" alignItems="left">
        <FaroText variant="labelL" color={neutral[100]}>
          Colorize
        </FaroText>
        <HelpPopover
          title="Waypoints color"
          description="Customize the waypoints color based on their elevation or capture date. Waypoints without that information will appear grey."
        />
      </Stack>
      <Dropdown
        dark
        fullWidth={false}
        shouldCapitalize={false}
        options={dropdownOptions}
        value={waypointsOption}
        onChange={(e) => {
          const option = e.target.value;
          if (validateEnumValue(option, WaypointsColoringOptions)) {
            dispatch(setWaypointsColoring(option));
          }
        }}
      />
    </Stack>
  );
}
